import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      fontFamily: 'Nunito',
    },
    appbar: {
      background: '#FFFFFF',
      position: "fixed",
      boxShadow: "20",
      top: "0%",
      width: "100%",
      zIndex: "1",
      padding: "10px"
    },
    appbarWrapper: {
      width: '80%',
      margin: '0 auto',
      display: "flex",
      
    },
    appbarTitle: {
   
    },
    icon: {
      color: '#000000',
      fontSize: '2rem',
    },
    colorTexttwo: {
      color: "#000000",
      fontFamily: "Helvetica",
      fontSize: '3vh',
      verticalAlign: "center",
      flexGrow: "1",
      fontWeight: "500"
    },
    colorText: {
      color: '#000000',
      fontFamily: "Arial",
      fontSize: "3.5vh",

      verticalAlign: "center",
      flexGrow: "1",
      fontWeight: "500"
    },
    colorTexto: {
      color: '#000000',
      fontFamily: "Arial",
      fontSize: "2.8vh",
      verticalAlign: "center",
      flexGrow: "1",
      fontWeight: "500"
    },
    container: {
      textAlign: 'center',
    },
    title: {
      color: '#fff',
      fontSize: '4.5rem',
    },
    goDown: {
      color: '#000000',
      fontSize: '4rem',
    },
    links:{
        paddingLeft: "14px",
        paddingRight: "14px",
        fontFamily: "Arial",
        marginTop: "2px",
        textDecoration: "none",
        color: "#000000",
    },
    desc:{
        color: "#000000",
        fontFamily: "Helvetica",
        fontWeight: "500"
    },
    leftparagraph: {
        maxWidth: "500px",
        textAlign: "left",
        lineHeight: "25px",
        float: "left",
        background: "#FAFAFA",
        padding: "50px",
        width: "80%",
    },
    rightparagraph:{
        maxWidth: "500px",
        textAlign: "left",
        lineHeight: "25px",
        float: "right",
        paddingLeft: "50px",
        background: "#FAFAFA",
        padding: "50px",
        width: "100%"
    },
    wrapper: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        width: "100%"
    },
    spcbtw:{
        width: "50px",
    },
    important:{
        fontSize: "24px",
        color: "#000000",
        textAlign: "center",
        fontFamily: "Arial",
        fontWeight: "semibold"
    },
    linkx: {
      paddingLeft: "14px",
      paddingRight: "14px",
      fontFamily: "Arial",
      marginTop: "2px",
      textDecoration: "none",
      color: "#FFFFFF"
    },

    important: {
      fontSize: "25px"
    }
  }));