import React, { useEffect, useState } from 'react';
import {  IconButton, Toolbar, Collapse, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as Scroll } from 'react-scroll';
import { Link} from "react-router-dom";
import { useStyles } from '../header/styles';

const isMobile = window.innerWidth <= 500;
export default function Header() {
    //Func
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    useEffect(() => {
      setChecked(true);
    }, []);


    //Return
    return (
<div className={classes.root} id="header">

  {isMobile ? (
  <div style = {{marginTop: "80px"}} className={classes.container}>
     <h1 className={classes.title}>
       <span className={classes.colorTexttwo}>Herzlich Willkommen!</span>
     </h1>

     <div className = {classes.wrapper}>
        <div className = {classes.leftparagraph}>
           <Typography variant = "h6">Start</Typography>
           <Typography variant = "subtitle1">
           Die Großgrund Immobilien ist ein privat geführtes Immobilienunternehmen aus Mudersbach im Westerwald. Das Kerngeschäft besteht in dem An- und Verkauf, der Entwicklung, der Verwaltung und der Verpachtung von Immobilien im eigenen Bestand.
           </Typography>
     </div>
   <div className = {classes.spcbtw}/>
    <div className = {classes.rightparagraph}>
        <Typography variant = "h6">Akquise</Typography>
        <Typography variant = "subtitle1">
        Falls Sie uns ihre Immobilie anbieten möchten, gehen Sie bitte auf unsere <Link to="/akquise">Akquise-Seite</Link>
        </Typography>
    </div>
  </div>
  </div>
  
 ) :(
   <Collapse
     in={checked}
     {...(checked ? { timeout: 1000 } : {})}
      collapsedHeight={50}
      >
       <div className={classes.container}>
          <h1 className={classes.title}>
            <span className={classes.colorTexttwo}>Herzlich Willkommen!</span>
          </h1>
        <div className = {classes.wrapper}>
          <div className = {classes.leftparagraph}>
              <span className = {classes.important}>Start</span><br/>
              <span className = {classes.desc}>
              Die Großgrund Immobilien ist ein privat geführtes Immobilienunternehmen aus Mudersbach im Westerwald. Das Kerngeschäft besteht in dem An- und Verkauf, der Entwicklung, der Verwaltung und der Verpachtung von Immobilien im eigenen Bestand.
              </span>
          </div>
          <div className = {classes.rightparagraph}>
              <span className = {classes.important}>Akquise</span><br/>
              <span className = {classes.desc}>
              Falls Sie uns ihre Immobilie anbieten möchten, gehen Sie bitte auf unsere <Link to="/akquise">Akquise-Seite</Link>
              </span>
          </div>
        </div>
          <Scroll to="place-to-visit" smooth={true}>
            <IconButton>
              <ExpandMoreIcon className={classes.goDown} />
            </IconButton>
          </Scroll>
        </div>
      </Collapse>
  
       )}
  
      </div>
    );
  }