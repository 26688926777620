import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email'
import {useStyles} from './styles.jsx'
import {Link } from 'react-router-dom'

const isMobile = window.innerWidth <= 500;


const BottomComponent = () => {
    const classes = useStyles();
   return(
       <div className = {classes.bottomwrapper}>
         <p><Link to="/impressum" className = {classes.whitetext}>Impressum</Link></p>
         <p><Link to ="/datenschutz" className = {classes.whitetext}>Datenschutz</Link></p>
         <p className={classes.graytext}>© {new Date().getFullYear()} Großgrund Immobilien </p>
       </div>
   )
}

export default BottomComponent;