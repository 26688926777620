import React from 'react'
import Beltuna from "../beltuna"
import { Grid, Card, Typography } from "@material-ui/core"
import {useStyles} from './styles'

export default function Infobox(props){

    const classes = useStyles();
    return(
        <Card className = {classes.card}>
            <Grid className = {classes.trunkate} direction = "column" container spacing = {2}>
                <Grid item>
                {props.media}            
                </Grid>
                <Grid item>
                  <Typography variant = "h6" className = {classes.maintype}> {props.maintype} </Typography>
                </Grid>
                <Grid item>
                  <Beltuna summary="Weitere Informationen" text={props.description}/>
                </Grid>
            </Grid>
        </Card>
    )
}

