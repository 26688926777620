import { makeStyles } from '@material-ui/core/styles';


const isMobile = window.innerWidth < 500;

export const useStyles = makeStyles((theme) => ({
   trunkate:{
       padding: "50px",
       justifyContent: "center",
       maxWidth: "350px"
   },
   wrapper: {
       justifyContent: "center"
   },

   card: {
  
   }
 
}))