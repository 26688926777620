import React from 'react';
import {List, ListItem, ListItemText, ListItemIcon} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function Ankaufsprofil () {

    return (
        <List>
            <ListItem>
                <ListItemIcon>
                    <CheckCircleIcon/>
                </ListItemIcon>
                <ListItemText primary = "Frei finanziert oder öffentlich gefördert"/>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                <CheckCircleIcon/>
                </ListItemIcon>
                <ListItemText>
                Eigentum (Freehold) und Erbbaurecht (Leasehold)
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                <CheckCircleIcon/>
                </ListItemIcon>
                <ListItemText>
                Gute Mikrolage
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                <CheckCircleIcon/>
                </ListItemIcon>
                <ListItemText>
                Asset- oder Share Deal (Nur inländische Kapitalgesellschaften)
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                <CheckCircleIcon/>
                </ListItemIcon>
                <ListItemText>
                Projektentwicklungen, Forward-Deals oder Forward-Funding Deals
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                <CheckCircleIcon/>
                </ListItemIcon>
                <ListItemText>
                Mit oder ohne Exposé
                </ListItemText>
            </ListItem>
        </List>
    )
}