import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardContent, Grid, Typography, Container} from '@material-ui/core/';


const useStyles = makeStyles((theme) => ({
   
    wrapper: {
       justifyContent: "center",
       alignContent: "center"
    },

    subtitle: {
        padding: "20px"
    }
}));




export default function Topcomponent(){
    const classes = useStyles()
    return (
  <Grid container direction = "column">
        <Grid item>
           <Typography variant = "h6" align = "center">
                Akquise
          </Typography>
       </Grid>
       <Grid item>
            <Grid container className = {classes.wrapper}>
                  <Grid item className = {classes.subtitle} xs = {12} md = {6}>
                      <Typography variant = "subtitle1" align = "center">
                         Für unseren Bestand suchen wir ansprechende Immobilien in den Bundesländern Rheinland Pfalz, Nordrhein-Westfalen und Hessen. Gerne können Sie uns Ihre Immobilien direkt unter der E-Mail Adresse sekretariat@grossgrund.com anbieten.
                      </Typography>
                  </Grid>
             </Grid>
      </Grid>
      </Grid>
    )
}