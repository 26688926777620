import { makeStyles } from '@material-ui/core/styles';
const isMobile = window.innerWidth <= 900;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile? "auto" : "100vh",
  },
  appbar: {
    background: '#FFFFFF',
    position: "fixed",
    top: "0%",
    width: "100%",
  },
  appbarWrapper: {
    width: '80%',
    margin: '0 auto',
    display: "flex",
    
  },
  appbarTitle: {
 
  },
  icon: {
    color: '#000000',
    fontSize: '2rem',
  },
  colorTexttwo: {
    color: "#000000",
    fontFamily: "Helvetica",
    fontSize: '3vh',
    verticalAlign: "center",
    flexGrow: "1",
    fontWeight: "500"
  },
  colorText: {
    color: '#000000',
    fontFamily: "Arial",
    fontSize: '3.5vh',
    verticalAlign: "center",
    flexGrow: "1",
    fontWeight: "500"
  },
  container: {
    textAlign: 'center',
    overflowY: "scroll"
  },
  title: {
    color: '#fff',
    fontSize: '4.5rem',
  },
  goDown: {
    color: '#000000',
    fontSize: '4rem',
  },
  links:{
      paddingLeft: "14px",
      paddingRight: "14px",
      fontFamily: "Arial",
      marginTop: "2px"
  },
  desc:{
      color: "#000000",
      fontWeight: "500"
  },
  leftparagraph: {
      maxWidth: isMobile ? "80%" : "40%",
      textAlign: "left",
      lineHeight: "25px",
      background: isMobile ? "none" :"#FAFAFA",
      padding: isMobile ? "30px": "60px",
      width: "100%",
  },
  rightparagraph:{
      maxWidth: isMobile ? "80%" :"40%",
      textAlign: "left",
      lineHeight: "25px",
      padding: isMobile ? "25px": "50px",
      background: isMobile ? "none": "#FAFAFA",
      width: "100%",
  },
  wrapper: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: "space-between",
      marginLeft: isMobile ? "none" : "5%",
      marginRight: isMobile ? "none" : "5%",
      width: "90%"
  },
  spcbtw:{
      width: "50px",
  },
  important:{
      fontSize: isMobile ? "18px" : "24px",
      color: "#000000",
      textAlign: "center",
      fontFamily: "Arial",
      fontWeight: "semibold"
  }
}));