import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core'
import BottomComponent from '../components/bottomcomponent';
import Header from '../components/header';
import {Helmet} from 'react-helmet';
import CookieConsent, { Cookies } from "react-cookie-consent";

const isMobile = window.innerWidth <= 500;

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: isMobile ? "100px": "10%",
        marginLeft: isMobile ? "5%" : "20%",
        marginRight: isMobile ? "5%":"20%",
        width: isMobile ? "90%": "60%",
        marginBottom: "100px"
    }
}))
const Datenschutz = () => {
const classes = useStyles();
return(
   <div>
       <Helmet>
           <title>Großgrund Immobilien | Datenschutz</title>
       </Helmet>
       <CookieConsent
  location="bottom"
  buttonText="Ich bin einverstanden"
  cookieName="einvestaendiserklaerung-cookies"
  style={{ background: "#000000" }}
  buttonStyle={{ color: "#000000", fontSize: "13px" , background: "#FFFFFF"}}
  expires={150}
>
 <Typography>Diese Webseite nutzt Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.</Typography>
</CookieConsent>
    <Grid  className = {classes.container} container direction = "column" spacing = {2}>
        <Grid item>
            <Typography variant = "h6">
                Datenschutz
            </Typography>
        </Grid>
    <br/>
      <Grid item>
        <Typography variant = "subtitle1">
        Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten sehr ernst. Alle personenbezogenen Informationen werden vertraulich und gemäß den gesetzlichen Vorschriften behandelt, wie in dieser Datenschutzerklärung erläutert.

Unserer Webseite kann selbstverständlich genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu irgendeinem Zeitpunkt persönliche Daten wie z.B. Name, Adresse oder E-Mail abgefragt werden, wird dies auf freiwilliger Basis geschehen. Niemals werden von uns erhobene Daten ohne Ihre spezielle Genehmigung an Dritte weitergegeben.

Datenübertragung im Internet, wie zum Beispiel über E-Mail, kann immer Sicherheitslücken aufweisen. Der komplette Schutz der Daten ist im Internet nicht möglich.
        </Typography>
    </Grid>
    <br/>
    <br/>
    <Grid item>
        <Typography variant = "h6">Cookies</Typography>
        <br/>
        <Typography variant = "subtitle1">Viele Internetseiten verwenden Cookies. Cookies sind unschädlich für Ihren Rechner und virenfrei. Sie dienen dazu, Internet-Angebote für die Besucher einer Webseite freundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Computer abgelegt werden und die Ihr Browser verwendet.

Wir verwenden in der Regel so genannte „Session-Cookies“. Diese werden nach Verlassen unserer Seite automatisch gelöscht. Andere Cookies bleiben auf Ihrem Computer gespeichert, bis Sie diese löschen. Diese Cookies helfen dabei, Ihren Rechner beim nächsten Besuch wiederzuerkennen.

Über die Browsereinstellungen können sie festlegen, dass Sie über neue Cookies informiert werden und Cookies jeweils annehmen müssen. Ebenso können Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen oder das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Werden Cookies desaktiviert, kann die Funktionalität unserer Website eingeschränkt sein.</Typography>
    </Grid>
   
    </Grid>
    <BottomComponent/>
</div>
)
}

export default Datenschutz