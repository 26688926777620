import React from 'react'
import {Link } from 'react-router-dom'
import { Toolbar, IconButton, SwipeableDrawer, Typography, Button, ListItem, ListItemText, ListItemIcon, Box, List, Divider, Grid} from '@material-ui/core';
import {useStyles} from './styles'
import SortIcon from '@material-ui/icons/Sort';


const AppBar = ({color}) => {
const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <>
   <ListItem>
    <Link to="/" onClick={toggleDrawer(anchor, false)} className = {classes.links}>Start</Link>
   </ListItem>
   <ListItem>
    <Link to="/akquise" onClick={toggleDrawer(anchor, false)} className = {classes.links}>Akquise</Link>
   </ListItem>
   <ListItem>
   <Link to="/impressum" onClick={toggleDrawer(anchor, false)} className = {classes.links}>Impressum</Link>
   </ListItem>
   <ListItem>
   <Link to="/datenschutz" onClick={toggleDrawer(anchor, false)} className = {classes.links}>Datenschutz</Link>
   </ListItem>
   </>
  );

  const isMobile = window.innerWidth <= 900;
  const classes = useStyles();
   return(
    <div className={classes.appbar}>
    <Toolbar className={classes.appbarWrapper}>
      {isMobile ? (
        <span className={classes.colorTexto}>Großgrund Immobilien</span>
      ):(
        <></>
      )
      }
     
    <>
      {isMobile ? (
    <>
    <React.Fragment>
        {(['top']).map((anchor) => (
          <React.Fragment key={anchor}>
            <IconButton onClick={toggleDrawer(anchor, true)}>
         <SortIcon className={classes.icon} />
       </IconButton>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </React.Fragment>
      </>
      ):(
        <React.Fragment>
          <Grid wrap = "nowrap" className = {classes.grid} direction = "row" container spacing={3}>
            <Grid item xs = {7} md = {7}>
            <Typography variant = "h5">Großgrund Immobilien</Typography>
            </Grid>
            <Grid item xs>
            <Link to = "/" className = {classes.links}>Startseite</Link>
            </Grid>
            <Grid item xs>
        <Link to = "/akquise"className = {classes.links}>Akquise</Link>
        </Grid>
        <Grid item xs>
        <Link to = "/impressum"className = {classes.links}>Impressum</Link>
        </Grid>
        <Grid item xs>
        <Link to = "/datenschutz"className = {classes.links}>Datenschutz</Link>
        </Grid>
        </Grid>
        </React.Fragment>
      )}
    </>
    </Toolbar>
  </div>
 )
}

export default AppBar