
import { makeStyles } from '@material-ui/core/styles';

const isMobile = window.innerWidth <= 500;
export const useStyles = makeStyles((theme) => ({
    bottomwrapper:{
       
        bottom: "0",
        background: "#000000",
        padding: "10px",
        textAlign: "center",
        justifyContent: "center",
        wordWrap:"break-word",
        marginTop: "auto",
        marginBottomo: "auto"
    },
    bottom:{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: isMobile ? "space-between" : "center",
    },
    left: {
    },
    right: {

    },
    spantext:{
      color: "white",
      fontWeight: "400",
      textAlign: "center"
    },
    whitetext:{
      color: "#FFFFFF",
      all: "initial",
      all: "unset",
    },
    graytext:{
      color: "#878787",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }
  }))