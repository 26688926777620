import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route, withRouter} from "react-router-dom";
import AkquiseNeu from './pages/AkquiseNeu'
import Impressum from './pages/Impressum'
import AppBar from './components/appbar'
import Datenschutz from './pages/Datenschutz';
import ScrollToTop from './components/scrollToTop';


function Error(){
  return(
    <h1>404</h1>
  )
}


const Ind = () => {
  return(
  <Router>
     <AppBar/>
     <ScrollToTop/>
      <Switch>
        <Route exact path="/" component = {App}/>
        <Route path="/akquise" component = {AkquiseNeu}/>
        <Route path ="/impressum" component = {Impressum}/>
        <Route path  = "/datenschutz" component = {Datenschutz}/>
        <Route path="*" component={Error}/>
      </Switch>
  </Router>

   )
}
ReactDOM.render(
  <React.StrictMode>
    <Ind />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
