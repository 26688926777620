import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core'
import BottomComponent from '../components/bottomcomponent';
import Header from '../components/header';
import {Helmet} from 'react-helmet';
import CookieConsent, { Cookies } from "react-cookie-consent";

const isMobile = window.innerWidth <= 500;

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: isMobile ? "100px": "10%",
        marginLeft: isMobile ? "5%" : "20%",
        marginRight: isMobile ? "5%":"20%",
        width: isMobile ? "90%": "60%",
        marginBottom: "20px"
    }
}))
const Impressum = () => {
const classes = useStyles();
return(
   <div>
    <Helmet>
        <title>Großgrund Immobilien | Impressum</title>
    </Helmet>
    <CookieConsent
  location="bottom"
  buttonText="Ich bin einverstanden"
  cookieName="einvestaendiserklaerung-cookies"
  style={{ background: "#000000" }}
  buttonStyle={{ color: "#000000", fontSize: "13px" , background: "#FFFFFF"}}
  expires={150}
>
 <Typography>Diese Webseite nutzt Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.</Typography>
</CookieConsent>
    <Grid  className = {classes.container} container direction = "column" spacing = {2}>
        <Grid item>
            <Typography variant = "h6">
                Impressum
            </Typography>
        </Grid>
        <Grid item>
         <Grid container direction = "column" spacing = {-1}>
             <Grid item>
                 <Typography variant = "subtitle1">
                      Großgrund Immobilien UG (haftungsbeschränkt)
                  </Typography>
               </Grid>
        <Grid item>
            <Typography variant = "subtitle1">
                Kölner Straße 23
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant = "subtitle1">
                57555 Mudersbach
            </Typography>
        </Grid>
      </Grid>
    </Grid>
    <br/>
    <Grid item>
        <Typography variant = "subtitle1">E-Mail: sekretariat@grossgrund.com</Typography>
        <Typography variant = "subtitle1">Inhaltlich Verantwortlich: Miyase Uracin</Typography>
    </Grid>
    <br/>
    <br/>
    <Grid item>
        <Typography variant = "h6">Haftung für Inhalte</Typography>
        <br/>
        <Typography variant = "subtitle1">
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen
        </Typography>
    </Grid>
    <br/>
    <br/>
    <Grid item>
        <Typography variant = "h6">Urheberrecht</Typography>
        <br/>
        <Typography variant = "subtitle1">Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</Typography>
    </Grid>
   
    </Grid>
    <BottomComponent/>
</div>
)
}

export default Impressum