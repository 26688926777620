import React from 'react'

export const Mehrfam = () => {

return (
<svg style = {{width: "100px", height: "100px"}} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 512 512">
<g transform="translate(1 1)">
	<g>
		<g>
			<path d="M502.467,493.933h-34.661c0.364-2.804,0.528-5.667,0.528-8.533c0-24.086-13.861-44.588-34.133-54.082V144.067
				c0-5.12-3.413-8.533-8.533-8.533h-128v-128c0-5.12-3.413-8.533-8.533-8.533h-230.4C53.613-1,50.2,2.413,50.2,7.533v486.4H7.533
				c-5.12,0-8.533,3.413-8.533,8.533S2.413,511,7.533,511h51.2h25.6h204.8H457.24h45.227c5.12,0,8.533-3.413,8.533-8.533
				S507.587,493.933,502.467,493.933z M417.133,426.269c-2.785-0.389-5.631-0.603-8.533-0.603c-6.442,0-12.761,1.097-18.731,3.148
				c-1.487-1.982-3.884-3.148-6.869-3.148c-5.12,0-8.533,3.413-8.533,8.533v2.615c-3.234,2.33-6.241,4.988-8.97,7.943
				c-0.431,0.456-0.855,0.918-1.27,1.388c-7.071-8.839-16.179-15.233-26.477-18.346c-1.479-1.357-3.518-2.134-5.95-2.134
				c-0.951,0-1.843,0.119-2.666,0.344c-1.927-0.225-3.884-0.344-5.867-0.344c-5.348,0-11.37,0.694-16.249,2.675
				c-0.604,0.204-1.204,0.414-1.798,0.639c-5.897-4.794-12.794-8.245-20.062-10.144c-0.077-0.021-0.156-0.036-0.232-0.057
				c-0.634-0.163-1.27-0.318-1.909-0.457c-0.518-0.114-1.042-0.213-1.565-0.311c-0.227-0.042-0.454-0.087-0.682-0.126
				c-0.549-0.095-1.1-0.179-1.655-0.256c-0.248-0.035-0.496-0.066-0.745-0.097c-0.509-0.063-1.02-0.122-1.534-0.169
				c-0.375-0.035-0.751-0.061-1.127-0.088c-0.402-0.028-0.803-0.061-1.208-0.08c-0.811-0.039-1.622-0.063-2.434-0.063
				c-0.628,0-1.251,0.013-1.871,0.036c-0.406,0.014-0.811,0.039-1.216,0.062c-0.162,0.01-0.326,0.017-0.488,0.028
				c-7.774,0.516-15.412,2.71-22.026,6.596V152.6h42.667h128V426.269z M67.267,16.067H280.6v119.467h-42.667
				c-5.12,0-8.533,3.413-8.533,8.533v265.387c-4.361-4.361-9.388-8.05-14.861-10.937c-0.483-0.258-0.971-0.508-1.461-0.752
				c-0.193-0.095-0.384-0.194-0.578-0.287c-6.09-2.954-12.717-4.892-19.642-5.617c-0.097-0.01-0.195-0.018-0.293-0.028
				c-0.804-0.08-1.612-0.143-2.424-0.19c-0.215-0.013-0.43-0.026-0.646-0.037c-0.917-0.043-1.837-0.072-2.762-0.072
				c-12.7,0-24.466,4.177-34.133,11.197v-2.663c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533v17.067
				c0,1.28,0.213,2.453,0.613,3.493c-4.19,6.905-7.077,14.689-8.293,22.961c-9.824,1.123-18.807,4.658-26.453,9.943v-2.263
				c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533v17.067c0,0.996,0.133,1.925,0.379,2.779
				c-4.231,6.843-7.11,14.586-8.296,22.82h-9.149V16.067z M131.65,459.977c0.169-0.015,0.34-0.025,0.51-0.038
				c0.45-0.035,0.9-0.069,1.354-0.09c0.669-0.03,1.342-0.05,2.02-0.05c5.12,0,8.533-3.413,8.533-8.533
				c0-23.893,18.773-42.667,42.667-42.667c21.333,0,39.253,15.36,41.813,36.693c0.577,2.308,1.947,4.21,3.821,5.474
				c0.118,0.083,0.239,0.161,0.362,0.238c0.132,0.081,0.263,0.163,0.4,0.237c0.315,0.174,0.643,0.333,0.985,0.47
				c0.013,0.005,0.025,0.01,0.038,0.016c0.391,0.156,0.799,0.287,1.22,0.392c0.001,0,0.001,0,0.002,0.001
				c0.508,0.169,1.085,0.234,1.695,0.215c2.805,0.041,5.414-1.493,6.837-3.626c0-0.001,0.001-0.001,0.002-0.002c0,0,0,0,0,0
				l0.853-0.853c6.827-8.533,16.213-13.653,27.307-13.653c5.762,0,11.521,1.614,16.69,4.632
				c-10.179,9.571-15.951,22.048-16.622,35.398c-0.045,0.873-0.068,1.753-0.068,2.637c0,5.934,0.987,11.621,2.94,17.067H93.72
				C97.714,475.562,112.924,461.678,131.65,459.977z M295.154,493.933c-3.26-5.514-5.167-12.033-5.167-17.92
				c0-9.436,3.72-17.937,10.114-24.151c1.222-1.133,2.525-2.179,3.901-3.126c1.333,0.01,2.674-0.268,3.905-0.883
				c18.773-10.24,40.96-0.853,47.787,17.92c0.853,2.56,3.413,5.12,7.68,5.12c0.81,0,1.706-0.258,2.606-0.716
				c2.141-0.351,3.834-1.923,5.074-4.404c3.307-7.027,8.427-12.637,14.659-16.57c1.345-0.375,2.5-1.039,3.419-1.94
				c5.861-2.946,12.505-4.53,19.469-4.53c23.893,0,42.667,18.773,42.667,42.667c0,2.56,0,5.973-0.853,8.533H295.154z"/>
			<path d="M280.6,220.867c-5.12,0-8.533,3.413-8.533,8.533v17.067c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V229.4
				C289.133,224.28,285.72,220.867,280.6,220.867z"/>
			<path d="M280.6,374.467c-5.12,0-8.533,3.413-8.533,8.533v17.067c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V383
				C289.133,377.88,285.72,374.467,280.6,374.467z"/>
			<path d="M280.6,272.067c-5.12,0-8.533,3.413-8.533,8.533v17.067c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V280.6
				C289.133,275.48,285.72,272.067,280.6,272.067z"/>
			<path d="M280.6,323.267c-5.12,0-8.533,3.413-8.533,8.533v17.067c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V331.8
				C289.133,326.68,285.72,323.267,280.6,323.267z"/>
			<path d="M280.6,169.667c-5.12,0-8.533,3.413-8.533,8.533v17.067c0,5.12,3.413,8.533,8.533,8.533s8.533-3.413,8.533-8.533V178.2
				C289.133,173.08,285.72,169.667,280.6,169.667z"/>
			<path d="M331.8,255c5.12,0,8.533-3.413,8.533-8.533V229.4c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533
				v17.067C323.267,251.587,326.68,255,331.8,255z"/>
			<path d="M331.8,203.8c5.12,0,8.533-3.413,8.533-8.533V178.2c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533
				v17.067C323.267,200.387,326.68,203.8,331.8,203.8z"/>
			<path d="M331.8,306.2c5.12,0,8.533-3.413,8.533-8.533V280.6c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533
				v17.067C323.267,302.787,326.68,306.2,331.8,306.2z"/>
			<path d="M331.8,357.4c5.12,0,8.533-3.413,8.533-8.533V331.8c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533
				v17.067C323.267,353.987,326.68,357.4,331.8,357.4z"/>
			<path d="M331.8,408.6c5.12,0,8.533-3.413,8.533-8.533V383c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533
				v17.067C323.267,405.187,326.68,408.6,331.8,408.6z"/>
			<path d="M383,306.2c5.12,0,8.533-3.413,8.533-8.533V280.6c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v17.067
				C374.467,302.787,377.88,306.2,383,306.2z"/>
			<path d="M383,255c5.12,0,8.533-3.413,8.533-8.533V229.4c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v17.067
				C374.467,251.587,377.88,255,383,255z"/>
			<path d="M383,408.6c5.12,0,8.533-3.413,8.533-8.533V383c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v17.067
				C374.467,405.187,377.88,408.6,383,408.6z"/>
			<path d="M383,357.4c5.12,0,8.533-3.413,8.533-8.533V331.8c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v17.067
				C374.467,353.987,377.88,357.4,383,357.4z"/>
			<path d="M383,203.8c5.12,0,8.533-3.413,8.533-8.533V178.2c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v17.067
				C374.467,200.387,377.88,203.8,383,203.8z"/>
			<path d="M92.867,323.267c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C84.333,319.853,87.747,323.267,92.867,323.267z"/>
			<path d="M92.867,374.467c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C84.333,371.053,87.747,374.467,92.867,374.467z"/>
			<path d="M92.867,425.667c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C84.333,422.253,87.747,425.667,92.867,425.667z"/>
			<path d="M92.867,67.267c5.12,0,8.533-3.413,8.533-8.533V41.667c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533
				v17.067C84.333,63.853,87.747,67.267,92.867,67.267z"/>
			<path d="M92.867,118.467c5.12,0,8.533-3.413,8.533-8.533V92.867c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533
				v17.067C84.333,115.053,87.747,118.467,92.867,118.467z"/>
			<path d="M92.867,169.667c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C84.333,166.253,87.747,169.667,92.867,169.667z"/>
			<path d="M92.867,220.867c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C84.333,217.453,87.747,220.867,92.867,220.867z"/>
			<path d="M92.867,272.067c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C84.333,268.653,87.747,272.067,92.867,272.067z"/>
			<path d="M144.067,118.467c5.12,0,8.533-3.413,8.533-8.533V92.867c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C135.533,115.053,138.947,118.467,144.067,118.467z"/>
			<path d="M144.067,67.267c5.12,0,8.533-3.413,8.533-8.533V41.667c0-5.12-3.413-8.533-8.533-8.533c-5.12,0-8.533,3.413-8.533,8.533
				v17.067C135.533,63.853,138.947,67.267,144.067,67.267z"/>
			<path d="M144.067,220.867c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C135.533,217.453,138.947,220.867,144.067,220.867z"/>
			<path d="M144.067,169.667c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C135.533,166.253,138.947,169.667,144.067,169.667z"/>
			<path d="M144.067,272.067c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C135.533,268.653,138.947,272.067,144.067,272.067z"/>
			<path d="M144.067,323.267c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C135.533,319.853,138.947,323.267,144.067,323.267z"/>
			<path d="M144.067,374.467c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533
				c-5.12,0-8.533,3.413-8.533,8.533v17.067C135.533,371.053,138.947,374.467,144.067,374.467z"/>
			<path d="M195.267,169.667c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
				v17.067C186.733,166.253,190.147,169.667,195.267,169.667z"/>
			<path d="M195.267,118.467c5.12,0,8.533-3.413,8.533-8.533V92.867c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
				v17.067C186.733,115.053,190.147,118.467,195.267,118.467z"/>
			<path d="M195.267,67.267c5.12,0,8.533-3.413,8.533-8.533V41.667c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v17.067
				C186.733,63.853,190.147,67.267,195.267,67.267z"/>
			<path d="M195.267,374.467c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
				v17.067C186.733,371.053,190.147,374.467,195.267,374.467z"/>
			<path d="M195.267,323.267c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
				v17.067C186.733,319.853,190.147,323.267,195.267,323.267z"/>
			<path d="M195.267,272.067c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
				v17.067C186.733,268.653,190.147,272.067,195.267,272.067z"/>
			<path d="M195.267,220.867c5.12,0,8.533-3.413,8.533-8.533v-17.067c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
				v17.067C186.733,217.453,190.147,220.867,195.267,220.867z"/>
			<path d="M246.467,118.467c5.12,0,8.533-3.413,8.533-8.533V92.867c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533
				v17.067C237.933,115.053,241.347,118.467,246.467,118.467z"/>
			<path d="M246.467,67.267c5.12,0,8.533-3.413,8.533-8.533V41.667c0-5.12-3.413-8.533-8.533-8.533s-8.533,3.413-8.533,8.533v17.067
				C237.933,63.853,241.347,67.267,246.467,67.267z"/>
		</g>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
)
}

export const Einfam = () => {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 58.365 58.365" style = {{height: "100px", width: "100px"}} >
<path d="M57.863,26.632l-8.681-8.061V5.365h-10v3.921L29.182,0L0.502,26.632c-0.404,0.376-0.428,1.009-0.052,1.414
	c0.375,0.404,1.008,0.427,1.414,0.052l3.319-3.082v33.349h16h16h16V25.015l3.319,3.082c0.192,0.179,0.437,0.267,0.681,0.267
	c0.269,0,0.536-0.107,0.732-0.319C58.291,27.641,58.267,27.008,57.863,26.632z M41.182,7.365h6v9.349l-6-5.571V7.365z
	 M23.182,56.365V35.302c0-0.517,0.42-0.937,0.937-0.937h10.126c0.517,0,0.937,0.42,0.937,0.937v21.063H23.182z M51.182,56.365h-14
	V35.302c0-1.62-1.317-2.937-2.937-2.937H24.119c-1.62,0-2.937,1.317-2.937,2.937v21.063h-14V23.158l22-20.429l14.28,13.26
	l5.72,5.311v0l2,1.857V56.365z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

	)
}

export const Portfolio = () => {
  return (
	<svg version="1.1" style = {{height: "100px", width: "100px"}} xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	viewBox="0 0 512 512">
<g>
   <g>
	   <path d="M503.551,449.951h-43.377V146.709c0-4.666-3.782-8.449-8.449-8.449H441.59V87.565c0-4.666-3.782-8.449-8.449-8.449
		   h-12.673V58.839c0-4.666-3.782-8.449-8.449-8.449h-7.603V38.561c0-4.666-3.782-8.449-8.449-8.449s-8.449,3.783-8.449,8.449v11.828
		   h-7.603c-4.667,0-8.449,3.783-8.449,8.449v20.277h-12.673c-4.667,0-8.449,3.783-8.449,8.449v50.696h-10.136
		   c-4.667,0-8.449,3.783-8.449,8.449v220.998h-58.018V189.898c0-4.666-3.782-8.449-8.449-8.449h-50.132v-38.734
		   c32.34-3.744,57.534-31.283,57.534-64.61c0-35.874-29.185-65.059-65.059-65.059c-29.129,0-54.315,19.203-62.388,46.564h-26.397
		   c-4.666,0-8.449,3.783-8.449,8.449v144.86H60.277c-4.666,0-8.449,3.783-8.449,8.449v228.583H8.449
		   C3.783,449.951,0,453.734,0,458.4s3.783,8.449,8.449,8.449h495.102c4.667,0,8.449-3.783,8.449-8.449
		   S508.218,449.951,503.551,449.951z M110.397,449.951H68.725v-24.414h21.844c4.666,0,8.449-3.783,8.449-8.449
		   c0-4.666-3.783-8.449-8.449-8.449H68.725v-14.871h21.844c4.666,0,8.449-3.783,8.449-8.449s-3.783-8.449-8.449-8.449H68.725
		   v-14.871h21.844c4.666,0,8.449-3.783,8.449-8.449s-3.783-8.449-8.449-8.449H68.725v-14.871h21.844
		   c4.666,0,8.449-3.783,8.449-8.449c0-4.666-3.783-8.449-8.449-8.449H68.725V298.46h21.844c4.666,0,8.449-3.783,8.449-8.449
		   c0-4.666-3.783-8.449-8.449-8.449H68.725v-14.871h21.844c4.666,0,8.449-3.783,8.449-8.449c0-4.666-3.783-8.449-8.449-8.449H68.725
		   v-19.976h41.672V449.951z M388.362,67.289h15.206v11.828h-15.206V67.289z M367.24,96.014h57.45v42.243h-57.45V96.014z
			M207.631,29.943c26.555,0,48.161,21.604,48.161,48.161c0,23.996-17.642,43.945-40.635,47.569V68.059
		   c0-4.666-3.783-8.449-8.449-8.449h-43.573C170.452,41.947,187.81,29.943,207.631,29.943z M177.422,82.567
		   c-4.666,0-8.449,3.783-8.449,8.449v344.431c0,0.037,0.005,0.072,0.006,0.109v14.394h-41.683V76.509h70.962v104.94h-12.387V91.016
		   C185.871,86.35,182.088,82.567,177.422,82.567z M227.551,376.156v73.795h-41.675V198.347h70.962v169.36H236
		   C231.334,367.707,227.551,371.49,227.551,376.156z M406.104,449.951H244.45v-65.345h161.653V449.951z M443.274,449.951h-20.273
		   v-73.795c0-4.666-3.782-8.449-8.449-8.449h-65.897V155.159h94.619V449.951z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M503.551,482.056H8.449c-4.666,0-8.449,3.783-8.449,8.449s3.783,8.449,8.449,8.449h495.102
		   c4.667,0,8.449-3.783,8.449-8.449S508.217,482.056,503.551,482.056z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M148.133,82.567c-4.666,0-8.449,3.783-8.449,8.449v344.431c0,4.666,3.783,8.449,8.449,8.449
		   c4.666,0,8.449-3.783,8.449-8.449V91.016C156.583,86.35,152.8,82.567,148.133,82.567z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M206.712,204.053c-4.666,0-8.449,3.783-8.449,8.449v223.294c0,4.666,3.783,8.449,8.449,8.449
		   c4.666,0,8.449-3.783,8.449-8.449V212.502C215.161,207.836,211.378,204.053,206.712,204.053z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M236.001,204.053c-4.666,0-8.449,3.783-8.449,8.449v133.613c0,4.666,3.783,8.448,8.449,8.448
		   c4.666,0,8.449-3.783,8.449-8.449V212.502C244.45,207.836,240.667,204.053,236.001,204.053z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M377.382,166.427c-4.667,0-8.449,3.783-8.449,8.449v171.238c0,4.666,3.782,8.449,8.449,8.449s8.449-3.783,8.449-8.449
		   V174.876C385.831,170.21,382.049,166.427,377.382,166.427z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M414.553,166.424c-4.667,0-8.449,3.783-8.449,8.449v171.24c0,4.666,3.782,8.449,8.449,8.449s8.449-3.782,8.449-8.449
		   v-171.24C423.002,170.207,419.22,166.424,414.553,166.424z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M383.574,103.423c-4.667,0-8.449,3.783-8.449,8.449v10.527c0,4.666,3.782,8.449,8.449,8.449
		   c4.667,0,8.449-3.783,8.449-8.449v-10.527C392.023,107.206,388.241,103.423,383.574,103.423z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M408.357,103.423c-4.667,0-8.449,3.783-8.449,8.449v10.527c0,4.666,3.782,8.449,8.449,8.449
		   c4.667,0,8.449-3.783,8.449-8.449v-10.527C416.806,107.206,413.023,103.423,408.357,103.423z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M387.237,395.496H263.315c-4.667,0-8.449,3.783-8.449,8.449s3.783,8.449,8.449,8.449h123.922
		   c4.667,0,8.449-3.783,8.449-8.449S391.905,395.496,387.237,395.496z"/>
   </g>
</g>
<g>
   <g>
	   <path d="M387.237,421.031H263.315c-4.667,0-8.449,3.783-8.449,8.449s3.783,8.449,8.449,8.449h123.922
		   c4.667,0,8.449-3.783,8.449-8.449S391.905,421.031,387.237,421.031z"/>
   </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

  )
}

