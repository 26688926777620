import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const Beltuna = (props) => {
    return(
        <div>
        <Accordion style = {{position: "relative"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{props.summary}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
             {props.text}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    )
}

export default Beltuna