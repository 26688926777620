import logo from '../logo.svg';
import './App.css';
import React from 'react';
import BottomComponent from '../components/bottomcomponent'
import Header from '../components/header'
import CookieConsent, { Cookies } from "react-cookie-consent";
import {Helmet} from 'react-helmet'
import {Typography} from '@material-ui/core'

function App() {
  return (
  <React.Fragment>
    <Helmet>
      <title>Großgrund Immobilien | Startseite</title>
      <meta name="description" content="Die Großgrund Immobilien ist ein privat geführtes Immobilienunternehmen aus Mudersbach im Westerwald. Das Kerngeschäft besteht in dem An- und Verkauf, der Entwicklung, der Verwaltung und der Verpachtung von Immobilien im eigenen Bestand."/>
      </Helmet>
       <Header/>    
      <div style = {{width: "100%"}}>
       <CookieConsent
  location="bottom"
  buttonText="Ich bin einverstanden"
  cookieName="einvestaendiserklaerung-cookies"
  style={{ background: "#000000" }}
  buttonStyle={{ color: "#000000", fontSize: "13px" , background: "#FFFFFF"}}
  expires={150}
>
 <Typography>Diese Webseite nutzt Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.</Typography>
</CookieConsent>
<BottomComponent/>
</div>
  </React.Fragment>
  );
}

export default App;
