import React from 'react'
import BottomComponent from '../components/bottomcomponent'
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardContent, Grid, Typography, Container} from '@material-ui/core/';
import {Mehrfam, Einfam, Portfolio} from '../components/icons.jsx' 
import {Helmet} from 'react-helmet'
import Infobox from '../components/infobox';
import Topcomponent from '../components/Topcomponent';
import Ankaufsprofil from '../components/Ankaufsprofil';
import CookieConsent, { Cookies } from "react-cookie-consent";

const useStyles = makeStyles((theme) => ({
   
    trunkate: {
        marginTop: "100px",
        justifyContent: "center",
        width:"100%",
      
    },
    description: {
        width: "40%",
        fontFamily: "Open Sans",
       lineHeight: "130%",
       textAlign: "left",
       color: "black",
       textAlign: "center"
    },
    important : {
        fontSize: "20px",
        color: "#000000",
        textAlign: "center",
        fontFamily: "Arial",
        fontWeight: "bold",
    },
    wrapper: {
        justifyContent: "center",
       alignContent: "center",
       
    },

    subtitle: {
        padding: "20px"
    },

    page: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    }
}));
const AkquiseNeu = () => {
    const classes = useStyles()
    return(
    <div className = {classes.page}>
          <CookieConsent
  location="bottom"
  buttonText="Ich bin einverstanden"
  cookieName="einvestaendiserklaerung-cookies"
  style={{ background: "#000000" }}
  buttonStyle={{ color: "#000000", fontSize: "13px" , background: "#FFFFFF"}}
  expires={150}
>
 <Typography>Diese Webseite nutzt Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.</Typography>
</CookieConsent>
        <Helmet>
            <meta name = "description" content = "Für unseren Bestand suchen wir ansprechende Immobilien in den Bundesländern Rheinland Pfalz, Nordrhein-Westfalen und Hessen. Auf dieser Webseite erhalten Sie ein kurzes Suchprofil und die Kontaktmöglichkeit zur Akquise."/>
            <title>Großgrund Immobilien | Akquise</title>
        </Helmet>
    <Grid container className = {classes.trunkate} direction = "column" spacing = {10}>
        <Grid item>
             <Topcomponent/>
        </Grid>  


      <Grid item>
       <Grid container spacing = {3} className = {classes.wrapper}>
           <Grid item>
                <Infobox media = {<Einfam/>} maintype = "Einfamilienhäuser" description = "Wir kaufen Einfamilienhäuser / Eigentumswohnungen zu guten Preisen an. Übergangsweise bieten wir Ihnen nach Absprache an, die Immobilie bis zu 6 Monate nach dem Verkauf weiter zu nutzen."/>
           </Grid>
           <Grid item>
               <Infobox media = {<Mehrfam/>} maintype = "Mehrfamilienhäuser" description = "Für unser Portfolio kaufen wir Mehrfamilienhäuser ab drei Einheiten an. Renovierungsbedarf ist dabei kein Hindernis."/>
           </Grid>
           <Grid item>
               <Infobox media = {<Portfolio/>} maintype = "Immobilienportfolios" description = "Immobilienportfolios werden oftmals als Asset- oder Sharedeal übertragen. Wir kaufen beide Varianten an (Ausnahme: Ausländische Kapitalgesellschaften)."/>
           </Grid>
       </Grid>
     </Grid>


       <Grid item>
           <Grid container spacing = {3} direction = "column" className = {classes.wrapper}>
            <Grid item>
                <Typography variant = "h6">Unser Ankaufsprofil</Typography>
            </Grid>
            <Grid item>
            <Ankaufsprofil/>
            </Grid>
           </Grid>
       </Grid>
       <Grid item>
       </Grid>
       <BottomComponent style = {{alignSelf: "flex-end"}}/>
   </Grid>
 </div>

    )
}

export default AkquiseNeu;